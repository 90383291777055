<template>
  <div class="col-12 white-bg search-place-2 d-flex flex-column px-5">
    <div class="row search-top">
      <div class="col-2">
        <img :src="activeTenant.image"/>
      </div>
      <div class="col name-level">
        <span class="name" v-html="activeTenant.name"></span>
        <span class="level"
              v-if="activeTenant.floors.length > 0">{{ translation.floor }} {{ activeTenant.floors.join(', ') }}</span>
      </div>
      <div class="col-auto button go-to-animation"
           @click="wayfindingTenant=activeTenant; activeTenant = null; wayfindingActive=true">
        <span class="action">{{ translation.goTo }}</span>
      </div>
    </div>
    <div class="row search-middle scroll">
      <div class="col">
                    <span class="info" v-html="activeTenant.description ">
                    </span>
      </div>
    </div>
    <div class="row search-bottom">
      <div class="col">
        <span class="text-left">
                      <template v-if="activeTenant.workingHours">
                        <template v-for="hours, key in activeTenant.workingHours" :key="key">
                          <template v-if="key!=='today'">
                          <p>{{ key }}: {{ hours }}</p>
                          </template>
                        </template>
                      </template>

        </span>
      </div>
      <div class="col name-level">
        <span class="text-middle" v-if="activeTenant.phone">Telefon:	{{ activeTenant.phone }}</span>
      </div>
      <div class="col-auto button-alt" @click="activeTenant=null">
        <span class="action">{{ translation.back }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapWritableState} from "pinia/dist/pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";
import {useControlsState} from "@/stores/controlsStore";

export default {
  name: "TenantResult",
  computed: {
    ...mapWritableState(useTenantsStore, ['activeTenant', 'wayfindingTenant']),
    ...mapWritableState(useControlsState, ['wayfindingActive']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        back: configStore.getTranslation('back', 'tenant'),
        floor: configStore.getTranslation('floor', 'tenant'),
        goTo: configStore.getTranslation('goTo', 'tenant'),
      }
    }
  }
}
</script>

<style scoped>
.search-middle {
  overflow-y: auto !important;
}


</style>
