import {defineStore} from 'pinia'
import {getTenantLetter} from "@/utils/getTenantLetter";
import Fuse from "fuse.js";


export const useTenantsStore = defineStore('tenants', {
    state: () => {
        return {
            tenants: [],
            categories: [],
            filters: {
                category: null,
                letter: null,
                search: null
            },
            filteredTenants: [],
            activeTenant: null,
            wayfindingTenant: null
        }
    },
    getters: {
        screensaverTenants: function () {
            /**
             @todo handle ordering on pinned tenants
             */
            const max = 5
            let screensaverTenants = []
            let notScreensaverTenants = []

            this.tenants.forEach((tenant) => {
                const pinned = tenant?.screensaver
                if (pinned === true) {
                    screensaverTenants.push(tenant)
                } else {
                    notScreensaverTenants.push(tenant)
                }
            })

            if (screensaverTenants.length >= max) {
                return screensaverTenants.slice(0, max)
            }

            screensaverTenants = [...screensaverTenants, ...notScreensaverTenants.sort(() => 0.5 - Math.random())]

            return screensaverTenants.slice(0, max)
        },
        tenantsByLocation: function () {
            let tenantsByLocation = {}

            this.tenants.forEach(tenant => {
                if (tenant.locations !== undefined && tenant.locations !== null) {
                    tenant.locations.forEach(location => {

                        if (tenantsByLocation[location.floor] === undefined) {
                            tenantsByLocation[location.floor] = {}
                        }

                        tenantsByLocation[location.floor][location.premises] = tenant
                    })
                }
            })

            return tenantsByLocation
        }
    },
    actions: {
        filter() {
            /**
             * @todo split into separate functions and clean up to make more sense
             */
            if (this.filters.category === null && this.filters.letter === null && this.filters.search === null) {

                this.filteredTenants = this.tenants
            } else {


                let filtered = []
                if (this.filters.category !== null || this.filters.letter !== null) {


                    this.tenants.forEach(tenant => {

                        let categoryFound = true
                        let letterFound = true
                        let searchFound = true

                        if (this.filters.category) {
                            /**
                             * No categories set for tenant
                             */
                            if (tenant.categories === null || tenant.categories.length === 0) {
                                categoryFound = false
                            }
                            /**
                             * Normalize categories to array
                             */
                            let categories = tenant.categories
                            if (typeof categories === 'object' &&
                                !Array.isArray(categories)) {
                                categories = Object.values(categories)

                            }
                            if (!categories.includes(this.filters.category)) {
                                categoryFound = false
                            }
                        }

                        if (this.filters.letter !== null && getTenantLetter(tenant) !== this.filters.letter) {
                            letterFound = false
                        }

                        if (categoryFound && letterFound && searchFound) {
                            filtered.push(tenant)
                        }


                    })
                } else if (this.filters.search !== null) {
                    const fuse = new Fuse(this.tenants, {
                        keys: [
                            {
                                name: 'normalizedName',
                                weight: 0.7
                            },
                            {
                                name: 'normalizedDescription',
                                weight: 0.3
                            }
                        ],
                        shouldSort: true,
                        threshold: 0.3,
                        location: 0,
                        distance: 10,
                    })

                    const result = fuse.search(this.filters.search)

                    result.forEach(resultItem => {
                            filtered.push(resultItem.item)
                        }
                    )
                }
                this.filteredTenants = filtered
            }
        },
        resetFilters() {
            this.filters.category = null
            this.filters.letter = null
            this.filters.search = null
        },
        setCategoryFilter(value) {
            this.activeTenant = null
            this.filters.category = value
            this.filter()
        },
        setLetterFilter(value) {
            this.activeTenant = null
            this.filters.letter = value
            this.filter()
        },
        setSearchFilter(value) {
            this.activeTenant = null
            this.filters.search = value
            this.filter()
        },
        hasFilters() {
            return this.filters.category !== null || this.filters.letter !== null || this.filters.search !== null
        },
        getTenantById(id) {

            let tenantToFind = null

            this.tenants.forEach(tenant => {
                if (tenant.id == id) {
                    tenantToFind = tenant
                }
            })

            return tenantToFind
        },
        getTenantByLocation(locationToUse) {

            let tenantToFind = null

            this.tenants.forEach(tenant => {
                if (tenant.locations !== undefined && tenant.locations !== null) {
                    tenant.locations.forEach(location => {

                        if (location.premises === locationToUse) {
                            tenantToFind = tenant
                        }

                    })
                }
            })

            return tenantToFind
        }
    }
})
