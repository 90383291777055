<template>
  <div class="col-12 white-bg search-place-2 p-0 d-flex flex-column"
       v-if="filters.category || filters.letter || filters.search"
       :class="{'results-empty':filteredTenants.length ===0,'results-1-row':filteredTenants.length >0 && filteredTenants.length <=4,'results-2-rows':filteredTenants.length >4 && filteredTenants.length <=8}">
    <div class="row search-middle height-fix m-0 p-0 scroll">
      <div class="col p-0">
        <div class="row m-0 ">
          <template v-if="filteredTenants.length>0">
            <div class="col-3 item" v-for="tenant in filteredTenants" :key="tenant.id" @click="activeTenant = tenant">
              <div class="fix-top-menu">
                <img :src="tenant.image"/>
              </div>
              <span class="title" v-html="tenant.name"></span>
              <span class="floor" v-if="tenant.floors.length > 0">{{ translation.floor }} {{
                  tenant.floors.join(', ')
                }}</span>
            </div>
          </template>
          <template v-else>
            <div class="col-12 no-results">{{ translation.noResults }}</div>
          </template>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import {mapState, mapWritableState} from "pinia/dist/pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";

export default {
  name: "SearchResultsList",
  computed: {
    ...mapWritableState(useTenantsStore, ['filteredTenants', 'activeTenant']),
    ...mapState(useTenantsStore, ['filters']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        noResults: configStore.getTranslation('noResults', 'search_results'),
        floor: configStore.getTranslation('floor', 'search_results'),
      }
    }
  }, mounted() {
    const store = useTenantsStore()
    store.filter()
  }
}
</script>

<style scoped>

.search-middle {
  overflow-y: auto !important;
}

img {
  height: auto !important;
}

.no-results {
  font-size: 8rem;
  text-align: center;
}

.search-place-2 {
  height: 95rem;
}

.search-place-2.results-empty {
  height: 20rem;
}

.search-place-2.results-1-row {
  height: 35rem;
}

.search-place-2.results-2-rows {
  height: 66rem;
}

.height-fix {
  height: 100% !important;
}
</style>
