<template>
  <section class="footer" id="footer-area">
    <div class="container position-relative">
      <div class="row">
        <div class="col-9 d-flex flex-column">
          <div class="row">
            <div class="col-3 position-relative flexing">
              <img :src="logo" class=""/>
            </div>
            <div class="col-3 position-relative flexing">
                <span class="footer-time copperplate">
                  {{ hour }}
                </span>
            </div>
            <div class="col-3 position-relative flexing">
                <span class="footer-day copperplate">
                  {{ date }}
                </span>
            </div>
            <div class="col-3 position-relative flexing">
              <template v-for="(lang, key) in languages" :key="lang.lang">
                <span class="footer-day copperplate lang"
                      :class="{active:lang.lang === this.currentLanguage}" @click="this.updateLang(lang)">
                {{ lang.name }}</span> {{ languages.length }}
                <span class="footer-day copperplate px-4" v-if="key <= languages.length -2"> | </span>
              </template>
            </div>
          </div>
        </div>

        <div class="col-3 d-flex flex-column right-menu position-absolute" @click="changeLayout">
          <div class="row right-menu-item active">
            <div class="col-12 position-relative">
              <span class="ps-5">{{ translation.changeLayout }}</span>
              <WheelchairIcon></WheelchairIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useConfigState} from "@/stores/configStore";
import {mapWritableState} from "pinia/dist/pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useFeedsState} from "@/stores/feedsStore";
import moment from "moment"
import {useNewsStore} from "@/stores/newsStore";
import WheelchairIcon from "@/components/icons/WheelchairIcon";
import {useControlsState} from "@/stores/controlsStore";
import layout from "@/const/layout";


export default {
  name: "FooterArea",
  components: {WheelchairIcon},
  emits: ['languageChanged'],
  data() {
    return {
      date: null,
      hour: null,
      interval: null,
      logo: require('@/assets/img/logo.png')
    }
  },
  computed: {
    ...mapWritableState(useConfigState, ['currentLanguage', 'languages', 'translation', 'config']),
    ...mapWritableState(useTenantsStore, ['tenants', 'categories']),
    ...mapWritableState(useFeedsState, ['feeds']),
    ...mapWritableState(useControlsState, ['layout']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        changeLayout: configStore.getTranslation('changeLayout', 'footer'),
      }
    }
  },
  mounted() {
    this.updateTime()
    this.interval = setInterval(this.updateTime, 500)

    const logo = this?.config?.parameters?.image

    if (logo !== undefined) {
      this.logo = logo
    }
  },
  methods: {
    updateTime: function () {

      this.hour = moment().locale(this.currentLanguage).format('LT')
      this.date = moment().locale(this.currentLanguage).format('LL')
    },
    updateLang: function (lang) {
      if (this.currentLanguage !== lang.lang) {
        this.currentLanguage = lang.lang
        this.tenants = this.feeds[this.currentLanguage].tenants
        this.categories = this.feeds[this.currentLanguage].category

        const newsStore = new useNewsStore()
        newsStore.news = this.feeds[this.currentLanguage].news
        newsStore.specialOffers = this.feeds[this.currentLanguage].sale
        newsStore.all = (([...newsStore.news, ...newsStore.specialOffers]).sort(() => Math.random() - 0.5))

        const configStore = new useConfigState()
        configStore.updateTranslation(this.currentLanguage)
        this.$emit('languageChanged')
      }
    }, changeLayout: function () {
      this.layout = this.layout === layout.NORMAL ? layout.ALTERNATIVE : layout.NORMAL;
      if (this.layout === layout.NORMAL) {
        document.body.classList.remove('alternative-layout')
      } else {
        document.body.classList.add('alternative-layout')
      }
    }
  }
}
</script>

<style scoped>

.lang {
  padding: 1rem;
}

.lang.active {
  border: 0 !important;
}
</style>
